
  /* -------------------------------------------------------- */

  /* SIDEBAR */

  
         /* NEW NAVBAR */
         .sidebar::after {
			display: none; 
		  }
		  
		  .sidebar {
			position: fixed;
			width: 220px;
			right: 0;
			top: 0;
			opacity: 1;
			transition: -webkit-transform 0.5s;
			transition: transform 0.5s;
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
			background: #000;
			z-index: 1;
			height: 100vh;
			overflow: auto;
			border-left: 1px solid #595a5f; 
			/* top: 81px; */
			z-index: 99;
		  }
	
		  
		  
		  .sidebar.isOpen {
			visibility: visible;
			transition: -webkit-transform 0.5s;
			transition: transform 0.5s;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			border-left: 1px solid var(--navborder);
			/* box-shadow: 0 10px 0  var(--navshadow);  */
		  }
		  
		  .mini {
			font-size: 0.6em;
			padding: 3em;
			display: block; }
		  
		  .content::before {
			content: "";
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1; }
		  
		  .content:after {
			content: "";
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			position: absolute;
			z-index: -1; }
		  
		  
		  @keyframes fadein {
			from {
			  opacity: 0; }
			to {
			  opacity: 1; } }
		  
		  @-webkit-keyframes fadein {
			from {
			  opacity: 0; }
			to {
			  opacity: 1; } }
		  
		  @-ms-keyframes fadein {
			from {
			  opacity: 0; }
			to {
			  opacity: 1; } }
		  
		  .content.isOpen {
			-webkit-transform: translate3d(0, 0, -300px);
			transform: translate3d(0, 0, -300px);
			opacity: 0.6;
			transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
			transition: -webkit-transform 0.3s;
			transition: transform 0.3s; }
	
		  
		   
	
		  .nav li {
			list-style-type: none; 
		  width: 100%;
		  cursor: pointer;
		  }
		  .nav li a img {
			  height: 25px;
			  width: 25px;
			  padding: 1px;
		  }
		  .nav li a {
			position: relative;
			display: block;
			padding: 15px 15px 15px 15px;
			border-bottom: 1px solid #343538;
			text-decoration: none; 
		  }
		  
		  
		  .nav { padding: 0; }
		  
		  .nav li a:hover {
			background: rebeccapurple; }
		  
		  .nav li a.active {
			box-shadow: inset 5px 0 0 #5b5, inset 6px 0 0 #222;
			background: #444; }
		  
  
	 #fontlogo{
	  font-family: monospace!important;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  }
  
  
  
	  .swal2-modal{
			background-color: var(--bckmodal);
			color: var(--reversable);
			border: 1px solid var(--modalborder);
			box-shadow: 0 0 10px var(--modalborder);
			background-image: radial-gradient(circle, var(--c5) 0%, var(--bckmodal) 100%);
	  }  
	  .taskInput,.form-control:disabled, .form-control[readonly]{
		background-color: transparent;
		color: var(--reversable);
		border: 1px solid var(--main);
	  }
	  .modal-header{
		border-bottom: 1px solid var(--main);
	  }
	  .modal-footer{
		border-top: 1px solid var(--main);
	  }
	
	  .swal2-title,.swal2-content{
		color: var(--main);
	  }
	  .form-control{
		border: 1px solid var(--main);
		
	  }
	  .form-control::placeholder{ color: var(--main); }
	
  
	  
  /* OVERLAY */
  
   .overlay{
	transition:opacity 1000ms;
	opacity:0;
	visibility:hidden ;
	 background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), to(#141414));
	  background-image: -webkit-linear-gradient(rgba(0, 0, 0, .3), #141414);
	  background-image: linear-gradient(rgba(0, 0, 0, .3), #141414);
	  position: fixed;
	  width: 100vw;
	  height: calc(100% - 81px);
	  overflow-y: scroll;
	  /* margin-top: 81px; */
   
  }
  .overlayisOpen{
	  opacity:1;
	  transition:opacity 1000ms;
	  visibility: visible;
	  z-index: 4;
  
  }
  
#sidenavbarButton{
    /* position: fixed;
    top: 2em;
    right: 0;
    z-index: 100;
    background-color: var(--settings-icon-color); */
}

  /* HAMBURGUER */

  /*!
   * Hamburgers
   * @description Tasty CSS-animated hamburgers
   * @author Jonathan Suh @jonsuh
   * @site https://jonsuh.com/hamburgers
   * @link https://github.com/jonsuh/hamburgers
   */
   .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter, opacity, filter;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    transition: 0.3s;
    outline: none; }
  
  .hamburger:hover {
    opacity: 0.7; }
  
  .hamburger.is-active:hover {
    opacity: 0.7; }
  
  .is-active {
    -webkit-transform: translate(12px, 0);
            transform: translate(12px, 0); }
  
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
  
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: var(--c1);
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  
  .hamburger-inner::before {
    top: -10px; }
  
  .hamburger-inner::after {
    bottom: -10px; }
  
  /* * Elastic */
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  .hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
            transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s; }
  
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
            transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }
  
  /*
       * Elastic Reverse
       */
  .hamburger--elastic-r .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  .hamburger--elastic-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
            transform: translate3d(0, 10px, 0) rotate(-135deg);
    transition-delay: 0.075s; }
  
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
            transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }
  
  #avatarIMG {
	width: 100%;
    height: auto;
  }
